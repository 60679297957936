.Login {
  position: absolute;
  margin: auto;
  padding-top: 100px;
  right: 0;

  left: 0;
  width: 300px;
  height: 100px;
  border-radius: 3px; }

.btn {
    width: 200px; }


.btn:focus {
  outline: none !important; }


.con {
    background-color: #000000; }
